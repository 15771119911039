<template>
    <div class="Rander">
        <div>
            <img src="../draw/img/LOGO.png" alt="" class="Mant">
        </div>
        <div>
            <div class="Seven">
                <img src="../draw/img/duihuan.png" alt="" class="Canvas">
                <div class="Sand">
                    <img src="../draw/img/zhuye.png" alt="" class="Uander">
                    <img src="../draw/img/one.png" alt="" class="Bander">
                </div>
                <div class="Sand">
                    <img src="../draw/img/pigtwo.png" alt="" class="Bander">
                    <img src="../draw/img/property.png" alt="" class="Uander">
                </div>
                <div class="Sand">
                    <img src="../draw/img/convert2.png" alt="" class="Uander">
                    <img src="../draw/img/pigthree.png" alt="" class="Bander">
                </div>
            </div>
        </div>
        <div class="Marren" @click="tiaozhuan()">
            <img src="../draw/img/four.png" alt="" class="Roner">
        </div>
    </div>
</template>


<script>
  export default {
        data(){
            return{

            }
        },
        methods:{
            tiaozhuan(){
                setTimeout(()=>{
                    this.$router.push({
                        path:"/Skippage",
                        query:{scene:'Home',pageName:"index"}
                    })
                },500)
            }
        }
    }
</script>

<style  lang="scss" scoped>
    .Rander{
        width: 100vw;
        height: 100vh;
        background-image: url("../draw/img/ditu.png");
        /* 让背景图基于容器大小伸缩 */
        background-size: cover;
        background-size:100% 100%;
    }

    .Mant{
        width: 200px;
        margin:50px;
    }
    .Seven{
        width: 90vw;
        margin-top: 13vh;
        background: rgb(255, 255, 255);
        margin-left: 5vw;
        border-radius: 30px;
        border: 6px solid #7FE08A;
        position: relative;
    }
    .Canvas{
        width: 50vw;
        position: absolute;
        top: -50px;
        left: 22%;
        right: 0;
        bottom: 0;
    }

    .Uander{
        width: 10vh;
        margin: 0.5vh;
    }

    .Sand{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .Bander{
        width: 20vh;
    }

    .Marren{
        display: flex;
        justify-content: space-around;
        margin-top: 3vh;
    }
    .Roner{
        width: 40vw;
    }
</style>